<template>
  <div>
    <vx-card>
      <TutorialSidebar
        :isSidebarActive="isSidebarActive"
        @refreshData="toggleRefreshData"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
        :dataId="dataId"
      />
      <div class="flex flex-wrap items-center space-between">
        <div class="mb-8">
          <h2 class="mb-0">Tutorial List</h2>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-full" align="right">
            <vs-button class="mb-md-0 mb-2"
                       align="right"
                       @click="addTutorial"
            >Add Tutorial
            </vs-button>
          </div>
        </div>
      </div>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="contents"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
        >
          <template slot="thead">
            <vs-th>FullName</vs-th>
            <vs-th>Length</vs-th>
            <vs-th>Url</vs-th>
            <vs-th class="action-col justify-center">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">
                {{ tr.name | capitalize }}
              </vs-td>
              <vs-td :data="tr.length">
                {{ tr.length }}
              </vs-td>
              <vs-td :data="tr.videoLink">
                {{ tr.videoLink }}
              </vs-td>
              <vs-td :data="tr._id" class="text-center">
                <a href="#" @click.stop="editDetailHandler(tr, tr._id)">
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
                </a>
                <a href="#" @click.stop="deleteHandler(tr._id)">
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"/>
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2" v-if="contents.length > 0">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {mapActions} from "vuex";
import TutorialSidebar from "./TutorialSidebar";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'

export default {
  components: {
    "v-select": vSelect,
    TutorialSidebar
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "fullName",
        dir: "asc",
        page: 1,
        limit: 25
      },
      serverResponded: false,
      contents: [],
      limitOptions: [
        {text: "5", value: 5},
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "100", value: 100}
      ],
      //add story sidebar
      isSidebarActive: false,
      sidebarData: {},
      dataId: "",

    };
  },
  methods: {
    ...mapActions("tutorial", [
      "fetchAllTutorials",
      "deleteTutorial"
    ]),
    getTutoriallList() {
      this.fetchAllTutorials(this.dataTableParams).then(res => {
        this.contents = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
      });
      this.serverResponded = true;
    },

    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getTutoriallList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTutoriallList();
    },
    addTutorial() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.isSidebarActive = val
    },
    toggleRefreshData(val = false) {
      if (val) {
        this.getTutoriallList();
      }
    },
    deleteHandler(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.acceptDeleteAlert(id)
        }
      })

    },
    acceptDeleteAlert(id) {
      this.$vs.loading()
      this.deleteTutorial(id)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Success',
            text: "Tutorial Deleted Successfully.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
          this.getTutoriallList();
        })
        .catch(err => {
          this.$vs.loading.close()
        })
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getTutoriallList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getTutoriallList();
  }
};
</script>
